import { useEffect, useState } from "react";
import { Avatar, Badge, List, Skeleton, Tabs } from "antd";
import { useNavigate } from "react-router-dom";
import { HeaderFilters } from "../../components/header_filters/headerFilters";
import { TiketsServices } from "../../services/apis/tiketsServices";
import { Tags } from "../../components/tags/tags";
import { category_tikets, colors_status, priority_tikets, status_tikets } from "../../constants/tikets";

import styles from "./tiketsStyles.module.scss";
import moment from "moment";
import Cookies from "js-cookie";
const count = 5;

interface DataType {
    id: number;
    id_auth: string;
    id_initial: string;
    title: string;
    description: string;
    date_ocurrency: string;
    category: string;
    priority: string;
    attachments?: any[];
    communication_history?: any[];
    status?: string;
  }

  interface Filter {
    text: string;
    cascade: [keyof DataType, any][];
  }  

export function TiketsPage() {
    const navigate = useNavigate();
    const ticketServices = new TiketsServices();
    const userProfile = Cookies.get('profile');
    const role = userProfile ? JSON.parse(userProfile)[0].role : '';
    const cnpjOwner = userProfile ? JSON.parse(userProfile)[0].institution_cnpj_owner : '';
    const [data, setData] = useState<DataType[]>([]);
    const [list, setList] = useState<DataType[]>([]);
    const [loading, setLoading] = useState(false);
    const [filteredData, setFilteredData] = useState<DataType[]>([]);
    const [filters, setFilters] = useState<Filter>({ text: "", cascade: [] });

    const ticketsCascadeFilters = [
      {
        value: 'category',
        label: 'Categoria',
        children: category_tikets.map((category) => {
            return {
              value: category.name,
              label: category.name,
            };
          }
        ),
      },
      {
        value: 'status',
        label: 'Status',
        children: status_tikets
        .filter((status) => status.name !== 'Todos')
        .map((status) => {
            return {
              value: status.name,
              label: status.name,
            };
          }
        ),
      },
      {
        value: 'priority',
        label: 'Prioridade',
        children: Object.keys(priority_tikets).map((priority) => {
            return {
              value: priority,
              label: priority,
            };
          }
        ),
      },
    ];


    const getTikets = async () => {
        const validRoles = ['ADM', 'ATENDIMENTO'];
        setLoading(true);
        try {
          if (role === 'startup') {
            const response = await ticketServices.getTicketsByEnterprise();
            setData(response.data);
            setList(response.data);
            setLoading(false);
            return;
          } else if (validRoles.includes(role)) {
            const response = await ticketServices.getByCnpjInnovationOwner(cnpjOwner);
            setData(response.data);
            setList(response.data);
            setLoading(false);
            return;
          } else {
            const response = await ticketServices.getTikets();
            setData(response.data);
            setList(response.data);

            setLoading(false);
          }
        } catch (error: any) {
            setLoading(false);
            console.error('Error getting tickets', error.message);
        }
    };

    const filterTiketsByStatus = (status: string) => {
        const statusByKey = status_tikets.find((item) => item.id === parseInt(status))?.name;
        if (statusByKey === 'Todos') {
            setList(data);
            return;
        }
        const filtered = data.filter((item) => item.status === statusByKey);
        setList(filtered);
    };

     //filtro unificado

     function removeAccents(str: string) {
      return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    }

    const applyFilters = (data: DataType[], { text, cascade }: Filter) => {
      // Convertendo o array de arrays para um objeto onde as chaves são os campos e os valores são arrays dos valores selecionados
      const cascadeObject = cascade.reduce((acc, [key, value]) => {
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(value);
        return acc;
      }, {} as Record<string, any[]>);

      return data.filter(item => {
        const textMatch = removeAccents(item?.title.toLowerCase()).includes(text.toLowerCase()) ||
          removeAccents(item?.id.toString().toLowerCase()).includes(text.toLowerCase()) ||
          removeAccents(item.description.toLowerCase()).includes(text.toLowerCase());
        const cascadeMatch = Object.entries(cascadeObject).every(([key, values]) => {
        if (key in item) {
          if (!values[0]) return true; // Se o array de valores está vazio, consideramos um match
          // Checa se o valor do item está incluído nos valores selecionados para esse filtro
          return values.includes(item[key as keyof DataType]);
        }
        return false; // Se a chave não existe, não consideramos um match
      });
        return textMatch && cascadeMatch;
      });
    };

    const handleTextFilterChange = (value: string) => {
      setFilters(prev => ({ ...prev, text: value }));
    };
  
    const handleCascadeFilterChange = (value: [keyof DataType, any][]) => {
      setFilters(prev => ({ ...prev, cascade: value }));
    };

    useEffect(() => {
      setFilteredData(applyFilters(list, filters));
    }, [filters, list]);

    const InfosContent = ({ item }: { item: DataType }) => {
        return (
          <div 
            className={styles.infosContent}
          >
            <div>
              <p>Prioridade</p>
              <Tags 
                color={priority_tikets[item?.priority as keyof typeof priority_tikets]}
                text={item?.priority ?? 'Sem prioridade'}
              />
            </div>
            <div>
              <p>Ultima atualização</p>
              <p className={styles.date}>{moment(item?.communication_history?.map((history) => history.date_register).sort((a, b) => moment(b).diff(moment(a)))[0]).format("DD/MM/YYYY - HH:mm:ss")}</p>
            </div>
          </div>
        );
    };

    const handleDetail = (idTicket: number) => {
        navigate(`/tickets-details/${idTicket}`);
    };

    useEffect(() => {
      getTikets();
    }, []);

    return (
        <div
            className={styles.container}
        >
            <HeaderFilters 
                changeSearch={handleTextFilterChange}
                changeCascader={handleCascadeFilterChange }
                disabledSearch={false}
                disabledCascade={false}
                optionsCascader={ticketsCascadeFilters}
                changeButton={() => {
                    navigate('/new-tiket');
                }}
                text="Abrir chamado"
            />
            <Tabs
              onChange={filterTiketsByStatus}
              type="card"
              items={status_tikets.map((status) => {
                return {
                  label: status.name,
                  key: status.id.toString(),
                  children: 
                  <List
                    className="demo-loadmore-list"
                    loading={false}
                    itemLayout="horizontal"
                    loadMore={null}
                    dataSource={filteredData}
                    renderItem={(item) => (
                      <Badge.Ribbon 
                        text={item?.status} 
                        color={colors_status[item?.status as keyof typeof colors_status]}
                        style={{
                          top: 3,
                        }}
                      >
                        <List.Item
                          actions={[<a key="list-loadmore-edit" onClick={() => handleDetail(item.id)}>Visualizar</a>]}
                        >
                          <Skeleton avatar title={false} loading={loading} active>
                            <List.Item.Meta
                              title={<a>{'#' + item?.id + ' - ' + item?.title}</a>}
                              description={(item?.description.length > 100) ? item?.description.slice(0, 99) + '...' : item?.description}
                            />
                            <InfosContent item={item} />
                          </Skeleton>
                        </List.Item>
                      </Badge.Ribbon>
                    )}
                  />,
                };
              })}
            />
        </div>
    );
}