import axios from 'axios';
import Cookies from 'js-cookie';

export const api = axios.create({
  // baseURL: 'http://localhost:4001', //LOCAL
   baseURL: 'https://squid-app-x6qm9.ondigitalocean.app/', //PRODUCTION
  // baseURL: 'https://orca-app-so9hc.ondigitalocean.app', //STAGING
});

api.interceptors.request.use(config => {
  const token = Cookies.get('access_token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, error => {
  return Promise.reject(error);
});

api.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error?.response?.data?.error?.includes('invalid JWT: unable to parse or verify signature, token is expired')) {
      // Aqui você pode usar o redirecionamento ou emitir um evento
      window.location.href = '/new-access-control'; // Redirecionamento direto
    }
    return Promise.reject(error);
  }
);