import axios from "axios";
import { api } from "./configs";

export class UserService {
    getUsers = async () => {
        try {
            const response = await api.get(`/user/`);
            return response.data.data;
        } catch (error: any) {
            if (axios.isAxiosError(error) && error.response) {
                console.error(error.response.data.message);
                throw new Error(error.response.data.message || 'Unknown error');
            } else {
                console.error('Error sending request', error.message);
                throw new Error(error.message || 'Error sending request');
            }
        }
    }

    getUserByCnpjOwner = async (cnpjOwner: string) => {
        try {
            const response = await api.get(`/user/cnpj-owner/${cnpjOwner}`);
            return response.data.data;
        } catch (error: any) {
            if (axios.isAxiosError(error) && error.response) {
                console.error(error.response.data.message);
                throw new Error(error.response.data.message || 'Unknown error');
            } else {
                console.error('Error sending request', error.message);
                throw new Error(error.message || 'Error sending request');
            }
        }
    }

    upadateUser = async (id: number, data: any) => {
        try {
            const response = await api.put(`/user/update/${id}`, data);
            return response.data.data;
        } catch (error: any) {
            if (axios.isAxiosError(error) && error.response) {
                console.error(error.response.data.message);
                throw new Error(error.response.data.message || 'Unknown error');
            } else {
                console.error('Error sending request', error.message);
                throw new Error(error.message || 'Error sending request');
            }
        }
    }

    createUser = async (data: any) => {
        try {
            const response = await api.post(`/user/create`, data);
            return response.data.data;
        } catch (error: any) {
            if (axios.isAxiosError(error) && error.response) {
                console.error(error.response.data.message);
                throw new Error(error.response.data.message || 'Unknown error');
            } else {
                console.error('Error sending request', error.message);
                throw new Error(error.message || 'Error sending request');
            }
        }
    }

    createUserByAdmin = async (data: any) => {
        try {
            const response = await api.post(`/user/create-by-admin`, data);
            return response.data.data;
        } catch (error: any) {
            if (axios.isAxiosError(error) && error.response) {
                console.error(error.response.data.message);
                throw new Error(error.response.data.message || 'Unknown error');
            } else {
                console.error('Error sending request', error.message);
                throw new Error(error.message || 'Error sending request');
            }
        }
    }
}