import { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { EyeIcon, EyeOff } from "lucide-react";
import { AccessControl as AccessControlClass } from '../../services/apis/accessControlServices';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { setFeatureScreenConfirmation, setMessageScreenConfirmation, setScreenConfirmation, setSubMessageScreenConfirmation } from "../../store/screenConfirmationSlice";
import { ScreensConfirmation } from "../../components/confirmationScreen/screens";
import { RootState } from "../../store/store";

import * as Yup from 'yup';

import styles from "./newPasswordStyles.module.scss";
import Cookies from "js-cookie";

// Esquema de validação utilizando Yup
const LoginSchema = Yup.object().shape({
    newPassword: Yup.string()
        .min(6, 'A nova senha deve ter pelo menos 6 caracteres')
        .required('A nova senha é obrigatória'),
    confirmNewPassword: Yup.string()
        .oneOf([Yup.ref('newPassword'), undefined], 'As senhas devem ser iguais')
        .required('A confirmação da nova senha é obrigatória'),
  });
  
export function RecoveryPassword(): JSX.Element {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = Cookies.get("user");
    const userParsed = user ? JSON.parse(user) : null;
    const idUser = userParsed.id;
    const screenConfirmation = useSelector((state: RootState) => state.screenStatus);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);

    const handleSubmit = async (values: any) => {
        const authServices = new AccessControlClass();
        dispatch(setFeatureScreenConfirmation('Alteração de senha'));
        dispatch(setScreenConfirmation('pending'));
        try {
          await authServices.changePassword({
            password: values.newPassword,
            id: idUser,
          });

          dispatch(setFeatureScreenConfirmation('Alteração de senha'));
          dispatch(setScreenConfirmation('approved'));
          dispatch(setMessageScreenConfirmation("Senha alterada com sucesso"));
          dispatch(
            setSubMessageScreenConfirmation(
              "Sua senha foi alterada com sucesso, você será redirecionado para a tela de login"
            )
          );
          setTimeout(() => {
            navigate("/new-access-control");
          }, 4000);
        } catch (error: any) {
          dispatch(setFeatureScreenConfirmation('Alteração de senha'));
          dispatch(setScreenConfirmation('rejected'));
          dispatch(setMessageScreenConfirmation("Erro ao alterar a senha"));
          dispatch(
            setSubMessageScreenConfirmation(
              error.message ?? "Erro inesperado, entre em contato com o suporte"
            )
          );
        }
    }
  return (
    <div className={styles.container}>
       { screenConfirmation.feature !== "Alteração de senha" &&
       <>
          <h1>Alterar senha</h1>
          <p>Informe a sua senha antiga e a nova senha para alterar a senha de acesso</p>
          <Formik           
              initialValues={{
                  oldPassword: '',
                  newPassword: '',
                  confirmNewPassword: '',
              }}
              validationSchema={LoginSchema}
              onSubmit={(values, { setSubmitting }) => {
                  handleSubmit(values);
                  setSubmitting(false);
              }}          
              >
            {({ isSubmitting }) => (
                <Form
                  className={styles.form}
                >

                <label htmlFor="newPassword">
                  Nova senha             
                </label>
                <Field type="password" name="newPassword">
                  {({ field }: any) => (
                    <div className={styles.passwordField}>
                      <input maxLength={10} type={!showNewPassword ? 'password' : 'text'} {...field} />
                      {!showNewPassword ? <EyeOff onClick={() => setShowNewPassword(!showNewPassword)} size={24} /> : <EyeIcon onClick={() => setShowNewPassword(!showNewPassword)} size={24} />}
                    </div>
                  )}
                </Field>
                <ErrorMessage name="newPassword" component="div" className={styles.error} />

                <label htmlFor="confirmNewPassword">
                  Confirme a nova senha             
                </label>
                <Field type="password" name="confirmNewPassword">
                  {({ field }: any) => (
                    <div className={styles.passwordField}>
                      <input maxLength={10} type={!showConfirmNewPassword ? 'password' : 'text'} {...field} />
                      {!showConfirmNewPassword ? <EyeOff onClick={() => setShowConfirmNewPassword(!showConfirmNewPassword)} size={24} /> : <EyeIcon onClick={() => setShowConfirmNewPassword(!showConfirmNewPassword)} size={24} />}
                    </div>
                  )}
                </Field>
                <ErrorMessage name="confirmNewPassword" component="div" className={styles.error} />

                <button type="submit" disabled={isSubmitting}>
                  Enviar
                </button>
              </Form>
            )}
          </Formik>
       </>}
        {screenConfirmation.feature === "Alteração de senha" && (
        <ScreensConfirmation
          status={screenConfirmation.screenStatus}
          feature={screenConfirmation.feature}
          subTitle={screenConfirmation.subMessage}         
          navigateTo="/new-access-control"
          buttonError="Voltar para o início"
          buttonSuccess="Voltar para o início"
        />
      )}
    </div>
  );
}