import { useEffect, useState } from "react";
import { InputText } from "../../inputText/inputText";
import { InputTextFilter } from "../../inputTextFilter/inputTextFilter";
import { AddressService } from "../../../services/apis/addressService";
import { clearStringOnlyNumbers, inputMaskCEP } from "../../../libs/masks";
import { AddressDataFormProps } from "./addressCommonInterfaces";
import styles from "./addressCommonDataStyles.module.scss";



export function AddressCommonDataForm({ 
    optionsUf, 
    currentAddress, 
    loandingAddress,
    setLoadingAddress,
    cities,
    setCities,
    addressData,
    setAddressData,
    errorCep,
    errorStreet,
    errorNumber,
    errorDistrict,
    errorCity,
    errorState,
}: AddressDataFormProps) {
    
    const addressService = new AddressService();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        if (name === 'zip_code') {
            if (value.length === 9) {
                setLoadingAddress(true);
                handleAddressByCep(clearStringOnlyNumbers(value));
            }

            setAddressData((prevData) => ({
                ...prevData,
                [name]: clearStringOnlyNumbers(value)
            }));
        }

        setAddressData((prevData) => ({
            ...prevData,
            [name]: value
        })
        );

    };

    const handleChangeFilterCity = (value: string) => {
        setAddressData((prevData) => ({
            ...prevData,
            city: value
        })
        );
    };

    const handleChangeFilterState = (value: string) => {
        setAddressData((prevData) => ({
            ...prevData,
            state: value
        })
        );
    };

    const handleSelectedUf = async (value: string) => {
        const codeUf = optionsUf.find(option => option.nome === value)?.id;

        if (!codeUf) {
            return;
        }

        try {
            const cities = await addressService.getCitiesByUf(codeUf);

            setCities(cities);
        } catch (error) {
            return;
        }
    };

    const handleAddressByCep = async (cep: string) => {
        try {
            const response = await addressService.getAddressByCep(cep);
            setAddressData({
                zip_code: response.cep,
                street: response.logradouro,
                district: response.bairro,
                city: response.localidade,
                state: optionsUf.find(option => option.sigla === response.uf)?.nome ?? 'state not found',
                country: 'Brasil',
                number: '',
                complement: ''
            });

            setLoadingAddress(false);
        } catch (error) {
            return;
        }
    };

    useEffect(() => {
        if (currentAddress) {
            setAddressData({
                street: currentAddress.street,
                number: currentAddress.number,
                complement: currentAddress.complemento,
                district: currentAddress.neighborhood,
                city: currentAddress.city,
                state: currentAddress.state,
                zip_code: currentAddress.cep,
                country: 'Brasil'
            });
        }
    }, [currentAddress]);

    return (
        <>
            <div className={styles.containerBasicData}>
                <div className={styles.grupo1}>
                    <InputText
                        label="CEP"
                        name="zip_code"
                        type="text"
                        placeholder="Digite o CEP"
                        value={inputMaskCEP(addressData.zip_code)}
                        onChange={handleChange}
                        error={errorCep.error}
                        errorMensage={errorCep.message}
                        required
                        styleLabel={{
                            color: 'var(--blue-900)'
                        }}
                        style={{
                            border: '1px solid var(--blue-900)'
                        }} />
                    <InputText
                        label="Rua"
                        name="street"
                        type="text"
                        placeholder="Digite o nome da rua"
                        value={addressData.street}
                        onChange={handleChange}
                        loading={loandingAddress}
                        error={errorStreet.error}
                        errorMensage={errorStreet.message}
                        required
                        styleLabel={{
                            color: 'var(--blue-900)'
                        }}
                        style={{
                            border: '1px solid var(--blue-900)'
                        }} />
                    <InputText
                        label="Número"
                        name="number"
                        type="text"
                        placeholder="Digite o número"
                        value={addressData.number}
                        onChange={handleChange}
                        error={errorNumber.error}
                        errorMensage={errorNumber.message}
                        required
                        styleLabel={{
                            color: 'var(--blue-900)'
                        }}
                        style={{
                            border: '1px solid var(--blue-900)'
                        }} />
                </div>
                <div className={styles.grupo2}>
                    <InputText
                        label="Complemento"
                        name="complement"
                        type="text"
                        placeholder="Digite o complemento"
                        value={addressData.complement}
                        onChange={handleChange}
                        styleLabel={{
                            color: 'var(--blue-900)'
                        }}
                        style={{
                            border: '1px solid var(--blue-900)'
                        }} />
                    <InputText
                        label="Bairro"
                        name="district"
                        type="text"
                        placeholder="Digite o bairro"
                        value={addressData.district}
                        onChange={handleChange}
                        loading={loandingAddress}
                        error={errorDistrict.error}
                        errorMensage={errorDistrict.message}
                        required
                        styleLabel={{
                            color: 'var(--blue-900)'
                        }}
                        style={{
                            border: '1px solid var(--blue-900)'
                        }} />
                </div>
                <div className={styles.grupo3}>
                    <InputTextFilter
                        label="Cidade"
                        name="city"
                        type="text"
                        placeholder="Digite a cidade"
                        value={addressData.city}
                        onChange={handleChangeFilterCity}
                        options={cities?.map(city => ({ value: city.nome }))}
                        loading={loandingAddress}
                        error={errorCity.error}
                        errorMensage={errorCity.message}
                        required
                        styleLabel={{
                            color: 'var(--blue-900)'
                        }}
                        style={{
                            border: '1px solid var(--blue-900)'
                        }} />
                    <InputTextFilter
                        label="Estado"
                        name="state"
                        type="text"
                        placeholder="Digite o estado"
                        value={addressData.state}
                        onChange={handleChangeFilterState}
                        selected={handleSelectedUf}
                        options={optionsUf?.map(option => ({ value: option.nome, sigla: option.sigla }))}
                        loading={loandingAddress}
                        error={errorState.error}
                        errorMensage={errorState.message}
                        required
                        styleLabel={{
                            color: 'var(--blue-900)'
                        }}
                        style={{
                            border: '1px solid var(--blue-900)'
                        }} />
                    <InputText
                        label="País"
                        name="country"
                        type="text"
                        placeholder="Digite o país"
                        value={addressData.country}
                        onChange={handleChange}
                        loading={loandingAddress}
                        styleLabel={{
                            color: 'var(--blue-900)'
                        }}
                        style={{
                            border: '1px solid var(--blue-900)'
                        }} />
                </div>
            </div>
        </>
    );
}
