import React, { useEffect } from "react";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Form,
  Input,
  notification,
  Select,
  Spin,
  Upload,
  UploadFile,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import { RootState } from "../../../store/store";
import {
  categoriasDeEmpresa,
  portesDeEmpresa,
} from "../../../constants/enterprises";
import {
  setAddress,
  setBasicData,
  setContacts,
} from "../../../store/formCreateStartUpSlice";
import {
  clearStringOnlyNumbers,
  convertMoneyInputMask,
  convertMoneyTextMask,
  inputMaskCEP,
  inputMaskCPFCNPJ,
  inputMaskTELWithDDD,
  removeMoneyMask,
} from "../../../libs/masks";
import {
  isValidUrl,
  validaEmail,
  validarCNPJ,
  validateBrazilianPhoneNumber,
  validateCEP,
  validateDateBeforeToday,
} from "../../../services/validation.service";
import { AddressService } from "../../../services/apis/addressService";
import { FilesServices } from "../../../services/apis/filesServices";

import styles from "./EnterpriseForm.module.scss";
import RegistrationFormatter from "./confirm/formatter";
import { RegistrationService } from "../../../services/apis/registrationSevice";
import { NotificationType } from "../../../types/commonTypes";

const { Option } = Select;
interface EnterpriseFormProps {
  optionsUfs: {
    id: number;
    sigla: string;
    nome: string;
    regiao?: {
      id: number;
      sigla: string;
      nome: string;
    };
  }[];
}

export function EnterpriseForm({ optionsUfs }: EnterpriseFormProps) {
  const currentWindowWidth = window.innerWidth;
  const userToken = Cookies.get("access_token");
  const customIdEnterprise = uuidv4();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  const [form] = Form.useForm();
  const [api, contextHolder] = notification.useNotification();
  const formatted = new RegistrationFormatter();
  const registrationService = new RegistrationService();
  const startUpBasicData = useSelector(
    (state: RootState) => state.formCreateStartUp.basicData
  );
  const startUpAddressData = useSelector(
    (state: RootState) => state.formCreateStartUp.address
  );

  const addressService = new AddressService();
  const fileService = new FilesServices();

  const [loading, setLoading] = React.useState(false);

  const openNotificationWithIcon = (
    type: NotificationType,
    title: string,
    description: string
  ) => {
    api[type]({
      message: title,
      description: description,
    });
  };

  const getActionUrl = (file: any) => {
    // Verificar o tipo de arquivo para decidir a rota
    if (file.type.startsWith("image/")) {
      return "https://squid-app-x6qm9.ondigitalocean.app/files/upload"; // Staging
      // return "http://localhost:4001/files/upload"; // Local
    } else {
      return "https://squid-app-x6qm9.ondigitalocean.app/files/upload/document"; // Staging
      // return "http://localhost:4001/files/upload/document"; // Local
    }
  };

  const setTypeFolder = (fileType: any) => {
    // Verificar o tipo de arquivo para decidir a rota
    if (fileType.type.startsWith("image/")) {
      return "images/";
    } else {
      return "docs/";
    }
  };

  const fileList: UploadFile[] = [];

  const handleChangeAddress = (
    event: React.ChangeEvent<HTMLInputElement>,
    target: { name: string }
  ) => {
    const { value } = event.target;
    const { name } = target;
    const key = name;
    if (key === "cep") {
      if (value.length === 8) {
        setLoading(true);
        handleAddressByCep(clearStringOnlyNumbers(value));
        form.setFieldsValue({
          cep: inputMaskCEP(value),
        });
      }

      dispatch(setAddress({ [name]: clearStringOnlyNumbers(value) }));
      return;
    }

    dispatch(setAddress({ [name]: value }));
  };

  const handleAddressByCep = async (cep: string) => {
    try {
      const response = await addressService.getAddressByCep(cep);
      dispatch(
        setAddress({
          street: response.logradouro,
          neighborhood: response.bairro,
          city: response.localidade,
          state:
            optionsUfs.find((option) => option.sigla === response.uf)?.nome ??
            "state not found",
        })
      );

      form.setFieldsValue({
        street: response.logradouro,
        neighborhood: response.bairro,
        city: response.localidade,
        state:
          optionsUfs.find((option) => option.sigla === response.uf)?.nome ??
          "state not found",
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      dispatch(
        setAddress({
          street: "",
          neighborhood: "",
          city: "",
          state: "",
        })
      );
      return;
    }
  };

  const handleChangeFilterState = (value: string) => {
    dispatch(setAddress({ state: value }));
  };

  const handleFinish = async (values: any) => {
    setLoading(true);
    try {
      const formattedData = formatted.startUpCreate({
        ...values,
        id_initial_create_enterprise:
          startUpBasicData.id_initial_create_enterprise,
      });
      await registrationService.registerEnterprise(formattedData);
      setLoading(false);
      openNotificationWithIcon(
        "success",
        "Sucesso",
        "Empresa cadastrada com sucesso"
      );
      goBack();
    } catch (error: any) {
      setLoading(false);
      openNotificationWithIcon(
        "error",
        "Erro",
        error['message'] ?? "Erro ao cadastrar empresa"
      );
      return;
    }
  };

  const deleteFile = async (id: string) => {
    try {
      const response = await fileService.deleteFile(id);
      return response;
    } catch (error) {
      return;
    }
  };

  useEffect(() => {
    dispatch(
      setBasicData({ id_initial_create_enterprise: customIdEnterprise })
    );
  }, []);

  return (
    <Form layout="vertical" form={form} onFinish={handleFinish}>
      {contextHolder}
      <Spin
        tip="Loading"
        size="large"
        className={styles.spinLoading}
        spinning={loading}
      >
        <div className={styles.formContainer}>
          <section className={styles.sectionBasicData}>
            <div className={styles.subSection}>
              <div className={styles.sectionTitle}>Dados Cadastrais</div>
              <div className={styles.inputGroupFlex}>
                <Form.Item
                  label="CNPJ"
                  name="cnpj"
                  rules={[
                    {
                      required: true,
                      message: "Campo CNPJ é obrigatório",
                      validator: (rule, value) => {
                        if (validarCNPJ(value)) {
                          return Promise.resolve();
                        }
                        return Promise.reject((rule.message = "CNPJ inválido"));
                      },
                    },
                  ]}
                  style={
                    currentWindowWidth > 1024
                      ? { width: "70%" }
                      : { width: "100%" }
                  }
                >
                  <Input
                    onChange={(e) =>
                      form.setFieldsValue({
                        cnpj: inputMaskCPFCNPJ(e.target.value),
                      })
                    }
                  />
                </Form.Item>
                <Form.Item
                  label="CEI"
                  name="cei"
                  style={
                    currentWindowWidth > 1024
                      ? { width: "29%" }
                      : { width: "100%" }
                  }
                >
                  <Input />
                </Form.Item>
              </div>
              <div className={styles.inputGroup}>
                <Form.Item label="Nome Fantasia" name="nome_fantasia">
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Razão Social"
                  name="razao_social"
                  rules={[
                    {
                      required: true,
                      message: "Campo Razão Social é obrigatório",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </div>
              <div className={styles.inputGroupFlex}>
                <Form.Item
                  label="Porte"
                  name="porte"
                  rules={[{ required: false }]}
                  style={
                    currentWindowWidth > 1024
                      ? { width: "33%" }
                      : { width: "100%" }
                  }
                >
                  <Select placeholder="Selecione">
                    {portesDeEmpresa.map((porte) => (
                      <Option key={porte} value={porte}>
                        {porte}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Categoria"
                  name="categoria"
                  rules={[
                    {
                      required: true,
                      message: "Campo Categoria é obrigatório",
                    },
                  ]}
                  style={
                    currentWindowWidth > 1024
                      ? { width: "33%" }
                      : { width: "100%" }
                  }
                >
                  <Select placeholder="Selecione">
                    {categoriasDeEmpresa.map((categoria) => (
                      <Option key={categoria} value={categoria}>
                        {categoria}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Data de Fundação"
                  name="data_fundacao"
                  rules={[
                    {
                      required: true,
                      message: "Campo Data de Fundação é obrigatório",
                      validator: (rule, value) => {
                        if (validateDateBeforeToday(value)) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          (rule.message =
                            "Data de Fundação não pode ser maior que a data atual")
                        );
                      },
                    },
                  ]}
                  style={
                    currentWindowWidth > 1024
                      ? { width: "33%" }
                      : { width: "100%" }
                  }
                >
                  <Input type="date" />
                </Form.Item>
              </div>
              <div className={styles.inputGroupFlex}>
                <Form.Item
                  label="Num. de Funcionários"
                  name="numero_funcionarios"
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="CNAE"
                  name="cnae"
                  rules={[{ required: false }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Vlr. Pagamento"
                  name="valor_pagamento"
                  rules={[{ required: false }]}
                >
                  <Input
                    onChange={(e) => {
                      form.setFieldsValue({
                        valor_pagamento: convertMoneyInputMask(e.target.value),
                      });
                    }}
                  />
                </Form.Item>
              </div>
            </div>
            <div className={styles.subSection}>
              <div className={styles.sectionTitle}>
                Responsável pela empresa
              </div>
              <Form.Item
                label="Nome do Responsável"
                name="nome_responsavel"
                rules={[{ required: false }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Cargo do Responsável"
                name="cargo_responsavel"
                rules={[{ required: false }]}
              >
                <Input />
              </Form.Item>
            </div>
          </section>
          <section className={styles.section}>
            <div className={styles.subSection}>
              <div className={styles.sectionTitle}>Contatos</div>
              <div className={styles.inputGroupFlex}>
                <Form.Item
                  label="Telefone"
                  name="phone"
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                  style={
                    currentWindowWidth > 1024
                      ? { width: "49%" }
                      : { marginTop: "10px" }
                  }
                >
                  <Input
                    onChange={(e) =>
                      form.setFieldsValue({
                        phone: inputMaskTELWithDDD(e.target.value),
                      })
                    }
                  />
                </Form.Item>
                <Form.Item
                  label="Celular"
                  name="cellphone"
                  rules={[
                    {
                      required: true,
                      message: "Campo Celular é obrigatório",
                      validator: (rule, value) => {
                        if (
                          validateBrazilianPhoneNumber(
                            clearStringOnlyNumbers(value)
                          )
                        ) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          (rule.message = "Celular inválido")
                        );
                      },
                    },
                  ]}
                  style={
                    currentWindowWidth > 1024
                      ? { width: "49%" }
                      : { marginTop: "10px" }
                  }
                >
                  <Input
                    onChange={(e) =>
                      form.setFieldsValue({
                        cellphone: inputMaskTELWithDDD(e.target.value),
                      })
                    }
                  />
                </Form.Item>
              </div>
              <div className={styles.inputGroup}>
                <Form.Item
                  label="Site"
                  name="site"
                  rules={[
                    {
                      required: false,
                      validator: (rule, value) => {
                        if (isValidUrl(value) || !value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          (rule.message =
                            "Site é obrigatório, verifique se a URL está correta")
                        );
                      },
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </div>
              <div className={styles.inputGroup}>
                <Form.Item
                  label="E-mail"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Campo E-mail é obrigatório",
                      validator: (rule, value) => {
                        if (validaEmail(value)) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          (rule.message = "E-mail inválido")
                        );
                      },
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </div>
            </div>
            <div className={styles.subSection}>
              <div className={styles.sectionTitle}>Endereço</div>
              <div className={styles.inputGroupFlex}>
                <Form.Item
                  label="CEP"
                  name="cep"
                  hasFeedback
                  validateStatus={loading ? "validating" : ""}
                  rules={[
                    {
                      required: true,
                      message: "Campo CEP é obrigatório",
                      validator: (rule, value) => {
                        if (validateCEP(value)) {
                          return Promise.resolve();
                        }
                        return Promise.reject((rule.message = "CEP inválido"));
                      },
                    },
                  ]}
                  style={
                    currentWindowWidth > 1024
                      ? { width: "25%" }
                      : { width: "100%" }
                  }
                >
                  <Input
                    onChange={(event) => {
                      handleChangeAddress(event, { name: "cep" });
                    }}
                    value={inputMaskCEP(startUpAddressData.cep)}
                  />
                </Form.Item>
                <Form.Item
                  label="Logradouro"
                  name="street"
                  hasFeedback
                  validateStatus={loading ? "validating" : ""}
                  rules={[
                    {
                      required: true,
                      message: "Campo Logradouro é obrigatório",
                    },
                  ]}
                  style={
                    currentWindowWidth > 1024
                      ? { width: "57%" }
                      : { width: "100%" }
                  }
                >
                  <Input
                    onChange={(event) => {
                      handleChangeAddress(event, { name: "street" });
                    }}
                    value={startUpAddressData.street}
                  />
                </Form.Item>
                <Form.Item
                  label="Número"
                  name="number"
                  rules={[
                    { required: true, message: "Campo Número é obrigatório" },
                  ]}
                  style={
                    currentWindowWidth > 1024
                      ? { width: "18%" }
                      : { width: "100%" }
                  }
                >
                  <Input
                    onChange={(event) => {
                      handleChangeAddress(event, { name: "number" });
                    }}
                    value={startUpAddressData.number}
                  />
                </Form.Item>
              </div>
              <div className={styles.inputGroup}>
                <Form.Item label="Complemento" name="complemento">
                  <Input
                    onChange={(event) => {
                      handleChangeAddress(event, { name: "complemento" });
                    }}
                    value={startUpAddressData.complemento}
                  />
                </Form.Item>
              </div>
              <div className={styles.inputGroupFlex}>
                <Form.Item
                  label="Bairro"
                  name="neighborhood"
                  hasFeedback
                  validateStatus={loading ? "validating" : ""}
                  rules={[
                    { required: true, message: "Campo Bairro é obrigatório" },
                  ]}
                  style={
                    currentWindowWidth > 1024
                      ? { width: "33%" }
                      : { width: "100%" }
                  }
                >
                  <Input
                    onChange={(event) => {
                      handleChangeAddress(event, { name: "neighborhood" });
                    }}
                    value={startUpAddressData.neighborhood}
                  />
                </Form.Item>
                <Form.Item
                  label="Cidade"
                  name="city"
                  hasFeedback
                  validateStatus={loading ? "validating" : ""}
                  rules={[
                    { required: true, message: "Campo Cidade é obrigatório" },
                  ]}
                  style={
                    currentWindowWidth > 1024
                      ? { width: "46%" }
                      : { width: "100%" }
                  }
                >
                  <Input
                    onChange={(event) => {
                      handleChangeAddress(event, { name: "city" });
                    }}
                    value={startUpAddressData.city}
                  />
                </Form.Item>
                <Form.Item
                  label="UF"
                  name="state"
                  hasFeedback
                  validateStatus={loading ? "validating" : ""}
                  rules={[
                    { required: true, message: "Campo UF é obrigatório" },
                  ]}
                  style={
                    currentWindowWidth > 1024
                      ? { width: "20%" }
                      : { width: "100%" }
                  }
                >
                  <Select
                    onChange={handleChangeFilterState}
                    value={startUpAddressData.state}
                    placeholder="Selecione"
                  >
                    {optionsUfs.map((option) => (
                      <Option key={option.sigla} value={option.nome}>
                        {option.sigla}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            </div>
          </section>
          <section className={styles.section}>
            <div className={styles.subSection}>
              <div className={styles.sectionTitle}>Anexos</div>
              <Upload
                action={getActionUrl}
                headers={{
                  Authorization: `Bearer ${userToken}`,
                  "X-Custom-Header":
                    startUpBasicData.id_initial_create_enterprise,
                }}
                listType="picture"
                defaultFileList={[...fileList]}
                beforeUpload={(file: any) => {
                  file.action = getActionUrl(file);
                  return true;
                }}
                onRemove={(file: any) => {
                  deleteFile(setTypeFolder(file) + file.response.fileName);
                }}
              >
                <Button icon={<UploadOutlined />}>Upload</Button>
              </Upload>
            </div>
          </section>
        </div>
        <div className={styles.boxButtom}>
          <Form.Item className={styles.formButton}>
            <button type="button" onClick={goBack}>
              Voltar
            </button>
          </Form.Item>
          <Form.Item className={styles.formButton}>
            <Button type="primary" htmlType="submit">
              Cadastrar
            </Button>
          </Form.Item>
        </div>
        <div className={styles.contentSpin} />
      </Spin>
    </Form>
  );
}
