import axios from "axios";
import { api } from "./configs";

export class ProfileServices {
    getProfileById = async (id: string) => {
        try {
            const response = await api.get(`/profile/read-profile/${id}`);
            return response.data;
        } catch (error: any) {
            if (axios.isAxiosError(error) && error.response) {
                // Acessar a mensagem específica do erro do servidor
                console.error(error.response.data.message);
                throw new Error(error.response.data.message || 'Unknown error');
            } else {
                // Erro que não é de resposta HTTP, por exemplo, erro de rede
                console.error('Error sending request', error.message);
                throw new Error(error.message || 'Error sending request');
            }
        }
    }
}