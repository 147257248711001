import { useEffect, useState } from "react";
import { 
    Descriptions, 
    DescriptionsProps, 
    Empty, 
    Modal, 
    Result, 
    Spin, 
    Tabs 
} from "antd";
import { Pencil } from "lucide-react";
import { InputText } from "../../components/inputText/inputText";
import { RegistrationService } from "../../services/apis/registrationSevice";

import styles from "./startUpStyles.module.scss";
import { clearStringOnlyNumbers, convertMoneyTextMask, inputMaskCEP, inputMaskCPFCNPJ, inputMaskTELWithDDD } from "../../libs/masks";
import moment from "moment";
import { InputTextFilter } from "../../components/inputTextFilter/inputTextFilter";
import { categoriasDeEmpresa, portesDeEmpresa } from "../../constants/enterprises";
import { AddressCommonDataForm } from "../../components/forms/addressCommon/AddressCommonDataForm";
import { IAddressDataForm, citiesProps } from "../../components/forms/addressCommon/addressCommonInterfaces";


interface IStartupDetails {
    data: {
        id: number;
        cnpj: string;
        cei: string;
        nome_fantasia: string;
        razao_social: string;
        categoria: string;
        data_fundacao: string;
        porte: string;
        numero_funcionarios: number;
        cnae: string;
        contabilidade: string;
        valor_pagamento: number;
        instituition_owner: string;
        instituition_cnpj_owner: string;
        owners: {
            nome_responsavel: string;
            cargo: string;
        };
        endereco: {
            street: string;
            number: string;
            neighborhood: string;
            city: string;
            state: string;
            cep: string;
            complemento: string;
        };
        contatos: {
            email: string;
            telefone: string;
            celular: string;
            site: string;
        };
    } | null
    open: boolean;
    hideModal: () => void;
    fetchData: () => void;
    setData: (data: any) => void;
    allUfs: {
        id: number,
        sigla: string,
        nome: string,
        regiao: {
            id: number,
            sigla: string,
            nome: string
        }
    }[]
}

export function Details({
    data, 
    open, 
    hideModal, 
    fetchData, 
    setData,
    allUfs
}: IStartupDetails) {
    const [loading, setLoading] = useState(false);
    const [errorUpdate, setErrorUpdate] = useState({
        error: false,
        message: ''
    });
    const [isModalOpen, setIsModalOpen] = useState({
        tabId: "",
        open: false,
        id: 0,
        value: "",
        fileldName: "",
        label: ""
    });
    const [ufSelected, setUfSelected] = useState('');
    const [loandingAddress, setLoadingAddress] = useState(false);
    const [cities, setCities] = useState<citiesProps[]>([]);
    const [addressData, setAddressData] = useState<IAddressDataForm>({
        street: "",
        number: "",
        complement: "",
        district: "",
        city: "",
        state: "",
        zip_code: "",
        country: ""
    });
    const [errorCep, setErrorCep] = useState({
        error: false,
        message: ""
    });
    const [errorStreet, setErrorStreet] = useState({
        error: false,
        message: ""
    });
    const [errorNumber, setErrorNumber] = useState({
        error: false,
        message: ""
    });
    const [errorDistrict, setErrorDistrict] = useState({
        error: false,
        message: ""
    });
    const [errorCity, setErrorCity] = useState({
        error: false,
        message: ""
    });
    const [errorState, setErrorState] = useState({
        error: false,
        message: ""
    });

    const tabs: any = {
        "0": "Dados basicos",
        "1": "Endereço",
        "2": "Contatos",
        "3": "Proprietários",
    }

    const startUpApiService = new RegistrationService();

    const itemsBasic: DescriptionsProps['items'] = [
        {
          key: '1',
          label: 'Razão Social',
          children: <div 
                        className={styles.boxChildren}
                        title="Editar Razão Social"
                        onClick={() => openModalEdit("0", data?.id ?? 0, data?.razao_social ?? '', 'razao_social', 'Razão Social')}
                    >
                      <p>{data?.razao_social}</p>
                      <Pencil 
                        size={18}
                      />
                    </div>,
        },
        {
          key: '2',
          label: 'Nome Fantasia',
          children: <div
                        className={styles.boxChildren}
                        title="Editar Nome Fantasia"
                        onClick={() => openModalEdit("0", data?.id ?? 0, data?.nome_fantasia ?? '', 'nome_fantasia', 'Nome Fantasia')}
                    >
                        <p>{data?.nome_fantasia}</p>
                        <Pencil 
                            size={18}
                        />
                    </div>,
        },
        {
          key: '3',
          label: 'CNPJ',
          children: <div
                        className={styles.boxChildren}
                        title="Editar CNPJ"
                        onClick={() => openModalEdit("0", data?.id ?? 0, inputMaskCPFCNPJ(data?.cnpj) ?? '', 'cnpj', 'CNPJ')}
                    >
                        <p>{inputMaskCPFCNPJ(data?.cnpj)}</p>
                        <Pencil 
                            size={18}
                        />
                    </div>,
        },
        {
          key: '4',
          label: 'CEI',
          children: <div
                        className={styles.boxChildren}
                        title="Editar CEI"
                        onClick={() => openModalEdit("0", data?.id ?? 0, data?.cei ?? '', 'cei', 'CEI')}
                    >
                        <p>{data?.cei}</p>
                        <Pencil 
                            size={18}
                        />
                    </div>,
        },
        {
          key: '5',
          label: 'Categoria',
          children: <div
                        className={styles.boxChildren}
                        title="Editar Categoria" 
                        onClick={() => openModalEdit("0", data?.id ?? 0, data?.categoria ?? '', 'categoria', 'Categoria')}   
                    >
                        <p>{data?.categoria}</p>
                        <Pencil 
                            size={18}
                        />
                    </div>,
        },
        {
          key: '6',
          label: 'Data de Fundação',
          children: <div
                        className={styles.boxChildren}
                        title="Editar Data de Fundação"
                        onClick={() => openModalEdit("0", data?.id ?? 0, moment(data?.data_fundacao).format('DD/MM/YYYY') ?? '', 'data_fundacao', 'Data de Fundação')}
                    >
                        <p>{moment(data?.data_fundacao).format('DD/MM/YYYY')}</p>
                        <Pencil 
                            size={18}
                        />
                    </div>,
        },
        {
          key: '7',
          label: 'Porte',
          children: <div
                        className={styles.boxChildren}
                        title="Editar Porte"
                        onClick={() => openModalEdit("0", data?.id ?? 0, data?.porte ?? '', 'porte', 'Porte')}
                    >
                        <p>{data?.porte}</p>
                        <Pencil 
                            size={18}
                        />
                    </div>,
        },
        {
          key: '8',
          label: 'Número de Funcionários',
          children: <div
                        className={styles.boxChildren}
                        title="Editar Número de Funcionários"
                        onClick={() => openModalEdit("0", data?.id ?? 0, data?.numero_funcionarios.toString() ?? '', 'numero_funcionarios', 'Número de Funcionários')}
                    >
                        <p>{data?.numero_funcionarios}</p>
                        <Pencil 
                            size={18}
                        />
                    </div>,
        },
        {
          key: '9',
          label: 'CNAE',
          children: <div
                        className={styles.boxChildren}
                        title="Editar CNAE"
                        onClick={() => openModalEdit("0", data?.id ?? 0, data?.cnae ?? '', 'cnae', 'CNAE')}
                    >
                        <p>{data?.cnae}</p>
                        <Pencil 
                            size={18}
                        />
                    </div>,
        },
        {
          key: '10',
          label: 'Contabilidade',
          children: <div
                        className={styles.boxChildren}
                        title="Editar Contabilidade"
                        onClick={() => openModalEdit("0", data?.id ?? 0, data?.contabilidade ?? '', 'contabilidade', 'Contabilidade')}
                    >
                        <p>{data?.contabilidade}</p>
                        <Pencil 
                            size={18}
                        />
                    </div>,
        },
        {
          key: '11',
          label: 'Valor de Pagamento',
          children: <div
                        className={styles.boxChildren}
                        title="Editar Valor de Pagamento"
                        onClick={() => openModalEdit("0", data?.id ?? 0, convertMoneyTextMask(data?.valor_pagamento) ?? '', 'valor_pagamento', 'Valor de Pagamento')}
                    >
                        <p>{convertMoneyTextMask(data?.valor_pagamento)}</p>
                        <Pencil 
                            size={18}
                        />
                    </div>,
        },
      ];

    const itemsEndereco: DescriptionsProps['items'] = [
        {
          key: '1',
          label: 'Rua',
          children: <div
                        className={styles.boxChildren}
                        title="Editar Rua"
                        onClick={() => openModalEdit("1", data?.id ?? 0, data?.endereco.street ?? '', 'street', 'Rua')}
                    >
                        <p>{data?.endereco.street}</p>
                        <Pencil 
                            size={18}
                        />
                    </div>
        },
        {
          key: '2',
          label: 'Número',
          children: <div
                        className={styles.boxChildren}
                        title="Editar Número"
                        onClick={() => openModalEdit("1", data?.id ?? 0, data?.endereco.number ?? '', 'number', 'Número')}
                    >
                        <p>{data?.endereco.number}</p>
                        <Pencil 
                            size={18}
                        />
                    </div>,
        },
        {
          key: '3',
          label: 'Bairro',
          children: <div
                        className={styles.boxChildren}
                        title="Editar Bairro"
                        onClick={() => openModalEdit("1", data?.id ?? 0, data?.endereco.neighborhood ?? '', 'neighborhood', 'Bairro')}
                    >
                        <p>{data?.endereco.neighborhood}</p>
                        <Pencil 
                            size={18}
                        />
                    </div>,
        },
        {
          key: '4',
          label: 'Cidade',
          children: <div
                        className={styles.boxChildren}
                        title="Editar Cidade"
                        onClick={() => openModalEdit("1", data?.id ?? 0, data?.endereco.city ?? '', 'city', 'Cidade')}
                    >
                        <p>{data?.endereco.city}</p>
                        <Pencil 
                            size={18}
                        />
                    </div>,
        },
        {
          key: '5',
          label: 'Estado',
          children: <div
                        className={styles.boxChildren}
                        title="Editar Estado"
                        onClick={() => openModalEdit("1", data?.id ?? 0, data?.endereco.state ?? '', 'state', 'Estado')}
                    >
                        <p>{data?.endereco.state}</p>
                        <Pencil 
                            size={18}
                        />
                    </div>,
        },
        {
          key: '6',
          label: 'CEP',
          children: <div
                        className={styles.boxChildren}
                        title="Editar CEP"
                        onClick={() => openModalEdit("1", data?.id ?? 0, inputMaskCEP(data?.endereco.cep) ?? '', 'cep', 'CEP')}
                    >
                        <p>{inputMaskCEP(data?.endereco.cep)}</p>
                        <Pencil 
                            size={18}
                        />
                    </div>,
        },
        {
          key: '7',
          label: 'Complemento',
          children: <div
                        className={styles.boxChildren}
                        title="Editar Complemento"
                        onClick={() => openModalEdit("1", data?.id ?? 0, data?.endereco.complemento ?? '', 'complemento', 'Complemento')}
                    >
                        <p>{data?.endereco.complemento}</p>
                        <Pencil 
                            size={18}
                        />
                    </div>,
        },
      ];

    const itemsContatos: DescriptionsProps['items'] = [
        {
          key: '1',
          label: 'Email',
          children: <div
                        className={styles.boxChildren}
                        title="Editar Email"
                        onClick={() => openModalEdit("2", data?.id ?? 0, data?.contatos.email ?? '', 'email', 'Email')}
                    >
                        <p>{data?.contatos.email}</p>
                        <Pencil 
                            size={18}
                        />
                    </div>
        },
        {
          key: '2',
          label: 'Telefone',
          children: <div
                        className={styles.boxChildren}
                        title="Editar Telefone"
                        onClick={() => openModalEdit("2", data?.id ?? 0, inputMaskTELWithDDD(data?.contatos.telefone) ?? '', 'telefone', 'Telefone')}
                    >
                        <p>{inputMaskTELWithDDD(data?.contatos.telefone)}</p>
                        <Pencil 
                            size={18}
                        />
                    </div>,
        },
        {
          key: '3',
          label: 'Celular',
          children: <div
                        className={styles.boxChildren}
                        title="Editar Celular"
                        onClick={() => openModalEdit("2", data?.id ?? 0, inputMaskTELWithDDD(data?.contatos.celular) ?? '', 'celular', 'Celular')}
                    >
                        <p>{inputMaskTELWithDDD(data?.contatos.celular)}</p>
                        <Pencil 
                            size={18}
                        />
                    </div>,
        },
        {
          key: '4',
          label: 'Site',
          children: <div
                        className={styles.boxChildren}
                        title="Editar Site"
                        onClick={() => openModalEdit("2", data?.id ?? 0, data?.contatos.site ?? '', 'site', 'Site')}
                    >
                        <p>{data?.contatos.site}</p>
                        <Pencil 
                            size={18}
                        />
                    </div>,
        },
      ];

    const itemsProprietarios: DescriptionsProps['items'] = [
        {
          key: '1',
          label: 'Nome do Responsável',
          children: <div
                        className={styles.boxChildren}
                        title="Editar Nome do Responsável"
                        onClick={() => openModalEdit("3", data?.id ?? 0, data?.owners.nome_responsavel ?? '', 'nome_responsavel', 'Nome do Responsável')}
                    >
                        <p>{data?.owners.nome_responsavel}</p>
                        <Pencil 
                            size={18}
                        />
                    </div>
        },
        {
          key: '2',
          label: 'Cargo',
          children: <div
                        className={styles.boxChildren}
                        title="Editar Cargo"
                        onClick={() => openModalEdit("3", data?.id ?? 0, data?.owners.cargo ?? '', 'cargo', 'Cargo')}
                    >
                        <p>{data?.owners.cargo}</p>
                        <Pencil 
                            size={18}
                        />
                    </div>,
        },
      ];
    
    function ShowChildren(idTab: string): JSX.Element {
        if (idTab === "1") {
            return (
                <Descriptions 
                    title="Dados basicos" 
                    bordered layout="vertical" 
                    items={itemsBasic} 
                    labelStyle={{
                        fontWeight: 'bold',
                        fontSize: '16px'
                    }}
                    className={styles.table}
                />
            )
        }
        if (idTab === "2") {
            return (
                <Descriptions 
                    title="Endereço" 
                    bordered 
                    layout="vertical" 
                    items={itemsEndereco}
                    labelStyle={{
                        fontWeight: 'bold',
                        fontSize: '16px'
                    }}
                    className={styles.table} 
                />
            )
        }
        if (idTab === "3") {
            return (
                <Descriptions 
                    title="Contatos" 
                    bordered 
                    layout="vertical" 
                    items={itemsContatos} 
                    labelStyle={{
                        fontWeight: 'bold',
                        fontSize: '16px'
                    }}
                    className={styles.table}    
                />
            )
        }
        if (idTab === "4") {
            return (
                <Descriptions 
                    title="Proprietários" 
                    bordered 
                    layout="vertical" 
                    items={itemsProprietarios} 
                    labelStyle={{
                        fontWeight: 'bold',
                        fontSize: '16px'
                    }}
                    className={styles.table}    
                />
            )
        }
        return <Empty />;
    }

    const openModalEdit = (
        tabId: string,
        id: number, 
        value: string, 
        fileldName: string, 
        label: string
    ) => {
        setIsModalOpen({
            tabId: tabId,
            open: true,
            id: id,
            value: value,
            fileldName: fileldName,
            label: label
        })
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsModalOpen({
            ...isModalOpen,
            value: e.target.value
        });
    }

    const handleSelectChange = (value: string) => {
        if (isModalOpen.fileldName === 'state') {
            setUfSelected(value);
        }

        setIsModalOpen({
            ...isModalOpen,
            value: value
        });
    }

    const handleOk = async (payload?: any) => {
        setLoading(true);
        try {
            if (isModalOpen.tabId === "0") {
                if (
                    (isModalOpen.fileldName === "valor_pagamento") ||
                    (isModalOpen.fileldName === "cnpj")
                ) {
                    await startUpApiService.upDateStartup(isModalOpen.id, { [isModalOpen.fileldName]: clearStringOnlyNumbers(isModalOpen.value) });
                } else {
                    await startUpApiService.upDateStartup(isModalOpen.id, { [isModalOpen.fileldName]: isModalOpen.value });
                }


                setData((prevData: any) => ({
                    ...prevData,
                    data: {
                        ...data,
                        [isModalOpen.fileldName]: isModalOpen.value
                    }
                }));
            }

            if ((isModalOpen.tabId === "1") && handleConfirm()) {
                await startUpApiService
                .upDateStartup(
                    isModalOpen.id, 
                    { endereco: {
                        street: addressData.street,
                        number: addressData.number,
                        neighborhood: addressData.district,
                        city: addressData.city,
                        state: addressData.state,
                        cep: addressData.zip_code,
                        complemento: addressData.complement
                    } 
                });

                setData((prevData: any) => ({
                    ...prevData,
                    data: {
                        ...data,
                        endereco: {
                            street: addressData.street,
                            number: addressData.number,
                            neighborhood: addressData.district,
                            city: addressData.city,
                            state: addressData.state,
                            cep: addressData.zip_code,
                            complemento: addressData.complement
                        }
                    }
                }));
            }

            if (isModalOpen.tabId === "2") {
                await startUpApiService
                .upDateStartup(
                    isModalOpen.id, 
                    { contatos: { 
                        ...data?.contatos,
                        [isModalOpen.fileldName]: isModalOpen.value 
                    } 
                });

                setData((prevData: any) => ({
                    ...prevData,
                    data: {
                        ...data,
                        contatos: {
                            ...data?.contatos,
                            [isModalOpen.fileldName]: isModalOpen.value
                        }
                    }
                }));
            }

            if (isModalOpen.tabId === "3") {
                await startUpApiService
                .upDateStartup(
                    isModalOpen.id, 
                    { owners: { 
                        ...data?.owners,
                        [isModalOpen.fileldName]: isModalOpen.value 
                    } 
                });

                setData((prevData: any) => ({
                    ...prevData,
                    data: {
                        ...data,
                        owners: {
                            ...data?.owners,
                            [isModalOpen.fileldName]: isModalOpen.value
                        }
                    }
                }));
            }

            setLoading(false);

            setIsModalOpen({
                tabId: "",
                open: false,
                id: 0,
                value: "",
                fileldName: "",
                label: ""
            });

            fetchData();
        } catch (error: any) {
            setLoading(false);
            setErrorUpdate({
                error: true,
                message: error.message
            });
        }
    }

    const handleCancel = () => {
        setIsModalOpen({
            tabId: "",
            open: false,
            id: 0,
            value: "",
            fileldName: "",
            label: ""
        })
    }

    const typeInputIsSelect = (fieldName: string) => {      
        if (
            (fieldName === 'categoria') || 
            (fieldName === 'porte')
        ) {
            return true;
        }
        return false;
    }

    const formIsAddress = (fieldName: string) => {
        if (
            (fieldName === 'state') || 
            (fieldName === 'city') ||
            (fieldName === 'street') ||
            (fieldName === 'number') ||
            (fieldName === 'neighborhood') ||
            (fieldName === 'cep') ||
            (fieldName === 'complemento')
        ) {
            return true;
        }
        return false;
    }

    const optionsSelect = (fieldName: string) => {
        if (fieldName === 'categoria') {
            return categoriasDeEmpresa.map((categoria) => {
                return {
                    sigla: categoria,
                    value: categoria
                }
            });
        }
        if (fieldName === 'porte') {
            return portesDeEmpresa.map((porte) => {
                return {
                    sigla: porte,
                    value: porte
                }
            });
        }
        if (fieldName === 'state') {
            return allUfs.map((uf) => {
                return {
                    sigla: uf.sigla,
                    value: uf.nome
                }
            });
        }
        if (fieldName === 'city') {
            return [
                { label: 'São Paulo', value: 'São Paulo' },
                { label: 'Rio de Janeiro', value: 'Rio de Janeiro' },
                { label: 'Belo Horizonte', value: 'Belo Horizonte' },
                { label: 'Curitiba', value: 'Curitiba' },
            ]
        }
        return [];
    }

    const handleConfirm = () => {
        if (addressData.zip_code === "") {
            setErrorCep({
                error: true,
                message: "CEP é obrigatório"
            });
            return false;
        }

        if (addressData.street === "") {
            setErrorStreet({
                error: true,
                message: "Rua é obrigatório"
            });
            return false;
        }

        if (addressData.number === "") {
            setErrorNumber({
                error: true,
                message: "Número é obrigatório"
            });
            return false;
        }

        if (addressData.district === "") {
            setErrorDistrict({
                error: true,
                message: "Bairro é obrigatório"
            });
            return false;
        }

        if (addressData.city === "") {
            setErrorCity({
                error: true,
                message: "Cidade é obrigatório"
            });
            return false;
        }

        if (addressData.state === "") {
            setErrorState({
                error: true,
                message: "Estado é obrigatório"
            });
            return false;
        }

        setErrorCep({
            error: false,
            message: ""
        });
        setErrorStreet({
            error: false,
            message: ""
        });
        setErrorNumber({
            error: false,
            message: ""
        });
        setErrorDistrict({
            error: false,
            message: ""
        });
        setErrorCity({
            error: false,
            message: ""
        });
        setErrorState({
            error: false,
            message: ""
        });

        return true;
    };

    useEffect(() => {
        if (data) {
            setUfSelected(data?.endereco.state);
        }
    }, [data]);

    return (
        <Modal
            title="Detalhes"
            open={open}
            onOk={hideModal}
            onCancel={hideModal}
            width={900}
        >
            <Tabs
                defaultActiveKey="1"
                type="card"
                size="small"
                items={new Array(4).fill(null).map((_, i) => {
                    const id = String(i + 1);
                    return {
                        label: `${tabs[i]}`,
                        key: id,
                        children: ShowChildren(id),
                    };
                })}
            />
            <Modal 
                open={isModalOpen.open} 
                onOk={handleOk} 
                onCancel={handleCancel}
                width={800}
            >
                {!loading && 
                !errorUpdate.error &&
                !typeInputIsSelect(isModalOpen.fileldName) &&
                !formIsAddress(isModalOpen.fileldName) &&
                <InputText 
                    name={isModalOpen.fileldName}
                    label={isModalOpen.label}
                    placeholder={isModalOpen.label}
                    value={isModalOpen.fileldName === "data_fundacao" ? moment(isModalOpen.value).format('YYYY-MM-DD') : isModalOpen.value}
                    onChange={handleChange}
                    type={(isModalOpen.fileldName === "data_fundacao") ? "date" : "text"}
                    styleLabel={{
                        color: 'black'
                    }}
                    style={{
                        border: '1px solid #d9d9d9',
                    }}
                />}
                {!loading &&
                !errorUpdate.error &&
                typeInputIsSelect(isModalOpen.fileldName) &&
                <InputTextFilter 
                    name={isModalOpen.fileldName}
                    label={isModalOpen.label}
                    placeholder={isModalOpen.label}
                    value={isModalOpen.value}
                    onChange={handleSelectChange}
                    options={optionsSelect(isModalOpen.fileldName)}
                    styleLabel={{
                        color: 'black'
                    }}
                    style={{
                        border: '1px solid #d9d9d9',
                    }}
                />}
                {!loading &&
                !errorUpdate.error &&
                formIsAddress(isModalOpen.fileldName) &&
                    <AddressCommonDataForm 
                        optionsUf={allUfs} 
                        currentAddress={data?.endereco}
                        loandingAddress={loandingAddress}
                        setLoadingAddress={setLoadingAddress}
                        cities={cities}
                        setCities={setCities}
                        addressData={addressData}
                        setAddressData={setAddressData}
                        errorCep={errorCep}
                        setErrorCep={setErrorCep}
                        errorStreet={errorStreet}
                        setErrorStreet={setErrorStreet}
                        errorNumber={errorNumber}
                        setErrorNumber={setErrorNumber}
                        errorDistrict={errorDistrict}
                        setErrorDistrict={setErrorDistrict}
                        errorCity={errorCity}
                        setErrorCity={setErrorCity}
                        errorState={errorState}
                        setErrorState={setErrorState}
                    />
                }
                {loading && !errorUpdate.error && <Spin />}
                {errorUpdate.error && !loading && 
                <Result 
                    status="error"
                    title="Erro ao atualizar os dados!"
                    subTitle={errorUpdate.message ?? "Verifique se os dados estão corretos e tente novamente!"}
                />}
            </Modal>
        </Modal>
    )
}