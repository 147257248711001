import { ScreensConfirmation } from "../../components/confirmationScreen/screens";
import { HeaderFilters } from "../../components/header_filters/headerFilters";

export function UserRegistration() {
    return (
        <div>
            <HeaderFilters 
                text="Criar de Usuário"
                changeButton={() => {}}
            />
        </div>
    );
}